.bigTile {
  padding: .25rem .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #FFF;
  background: $teal;
  text-transform: uppercase;
  border-radius: 4px;
  &--blue {
    background: $blue;
  }
  &--navy {
    background: $navy;
  }
  &--small {
    text-transform: none;
    font-size: .825rem
  }
}

.editPrimary {
  position: absolute;
  top: 10px; right: 10px;
  // transform: translateY(-50%);
}

.edit {
  position: absolute;
  top: 50%; right: 10px;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 1.25rem;
  color: $grey;
  &:hover {
    color: $navy;
  }
}

.checkIt {
  display: inline-block;
  width: 16px; height: 16px;
  input {
    display: none;
    & + label {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        display: inline-block;
        border: 1px solid $grey;
        width: 16px; height: 16px;
        border-radius: 3px;
      } 
      &:after {
        display: none;
        content: '\f00c';
        position: absolute;
        top: 2px; left: 3px;
        width: 16px; height: 16px;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        color: $grey;
      } 
    }
    &:checked + label {
      &:before {
        background: $grey;
      } 
      &:after {
        display: inline-block;
        color: #FFF;
      } 
    }
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 #ffab00c0;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 171, 0, 0);
  }

  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0 0 rgba(255, 171, 0, 0);
  }
}

.powers {
  // padding: 2rem 1rem;
  &__power {
    margin-bottom: .5rem;
    padding: 1rem;
    border: 2px solid $lightGrey;
    background: #FFF;
    border-radius: 5px;
    background: #FAFAFA;
    
    .powerHeader {
      &__top {
        @include bp(Kid) {
          @include flex;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        @include bp(Papa) {
          flex-wrap: nowrap;
        } 
      }

      &__btm {

      }

      &__imageUploaded, &__imageMissing {
        @include unicorn;
        height: 24px;
        background: $grey;
        padding: 0 .5rem;
        border: 2px solid $grey;
        border-radius: 12px;
        color: $white;
        font-size: .825rem;
        cursor: pointer;

        svg {
          margin-top: -1px;
        }
      }

      &__imageUploaded {
        background: $teal;
        border-color: $teal;
        &:hover {
          background: $white;
          color: $teal;
        }
      }

      &__imageMissing {
        background: $danger;
        border-color: $danger;
        &:hover {
          background: $white;
          color: $danger;
        }
      }

      &__left {
        position: relative;
        width: 100%;

        @include bp(Kid) {
          margin-bottom: 1rem;
        }  
  
        @include bp(Papa) {
          margin-bottom: 0;
        }

        .editDetails {
          position: absolute;
          top: 50%; left: 0;
          transform: translateY(-50%);
          margin-left: .25rem;
          padding: 0;
          border: 0;
          background: transparent;
          font-size: 1.25rem;
          color: $grey;
          &:hover {
            color: $navy;
          }
          i {
            font-size: 1rem;
          }
        }

        h3 {
          @include flex;
          justify-content: flex-start;
          margin: 0 0 .25rem;
          color: $teal;
          gap: .25rem;
          button {
            margin-right: .25rem;
          }
          i {
            margin-right: .25rem;
          }
          .pill {
            margin-left: .5rem;
            letter-spacing: 1px;
          }
        }
        p {
          margin: 0 0 .25rem;
          font-size: .75rem;
          color: $grey;
          &:last-child {
            margin-bottom: 0;
          } 
        }
      }

      &__tiles {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 10px;
        padding-right: 35px;
        width: 100%;
        position: relative;

        @include bp(Toddler) {
          flex-wrap: nowrap;
          padding-right: 0;
        }    

        @include bp(Kid) {
          justify-content: flex-end;
          margin-top: 0;
        }    

        @include bp(Papa) {
          width: auto;
        }    

      }
    }

    .powerStatusTransfer {
      background: $orange;
    }

    .powerStatus {
      @include flex;
      margin: .5rem 0 0;
      background: $teal;
      border-radius: .25rem;
      font-size: .75rem;
      font-weight: 400;
      color: #FFF;
      overflow: hidden;
      span {
        padding: .5rem;
        strong {
          text-transform: uppercase;
        }
      }
      &--red {
        background: $danger;
      }
      &--blue {
        background: $blue;
      }
    }

    &--expanded {
      .powerHeader {
        margin-bottom: 1rem;
      }
      .powerHeader__left {
        // padding-left: 2rem;
      }
    }

    &--highlighted {
      border-color: $orange;
      animation: pulse-orange 2s infinite;
    }

    &--executed, &--void {
      .powerHeader__left {
        padding-left: 0;
      }
    }

    &--charges {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      ul {
        margin: .25rem 0 0;
        padding: 0;
        list-style: none;
        li {
          font-size: .85rem;
        }
      }
      p {
        margin: .25rem 0 0;
        font-size: .85rem
      }

      form.register__form {
        margin-top: .5rem;
        max-width: 100%;
        padding-bottom: 0;
      }
    }

    &--customFields {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      h5 {
        margin-bottom: .5rem;
      }
      ol {
        margin: .25rem 0 0;
        padding: 0 0 0 1rem;
        li {
          font-size: .85rem;
        }
      }
      p {
        margin: .25rem 0 0;
        font-size: .85rem
      }

      form.register__form {
        margin-top: .5rem;
        max-width: 100%;
        padding-bottom: 0;
      }
    }

    &--info {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      .powerInfo {
        p {
          margin: .25rem 0 0;
          font-size: .85rem;
        }
      }
      form.register__form {
        margin-top: .5rem;
      }
    }

    &--county {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      .powerCounty {
        p {
          margin: .25rem 0 0;
          font-size: .85rem;
        }
      }

      .adjustmentRow__holdingJail {
        margin: .5rem 0 0; padding: 0;
      }
    }

    &--courtdates {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      min-height: 50px;

      .adjustmentRow__holdingJail {
        margin: .5rem 0 0; padding: 0;
      }
    }

    &--forfeiture {
      position: relative;
      @include innerPod;
      margin-bottom: 1rem;
      .powerForfeitures {
        &__empty {
          padding-right: 4.5rem;
        }
        p {
          margin: 0;
          font-size: .85rem;
        }
        .inputRow__block {
          margin: 0 0 .5rem;
        }
        &__actions {
          @include flex;
          gap: .5rem;
          & > button {
            flex: 1;
          }
        }
      }

      .adjustmentRow__holdingJail {
        margin: .5rem 0 0; padding: 0;
      }
    }

    &--indemnitors {
      overflow:hidden;
      margin-bottom: 1rem;
      .powerIndemnitors {
        @include flex;
        margin-top: .5rem;
        &__top {
          @include innerPod;
          position: relative;
          z-index: 1;
        }
        &__btm {
          position: relative;
          bottom: 0;
          margin: 0 1rem;
          margin-top: calc(-33px * var(--case-count, 0));
          @include innerPod;
          padding: 0;
          border-top: 0;
          transition: $base-transition;
          border-radius: 0;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          & > div {
            @include flex;
            padding: .5rem;
            border-bottom: 1px solid $lightGrey;
            font-size: .85rem;
            p {
              margin: 0;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
        &__left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        button {
          margin-right: .5rem;
          height: 75px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: .5rem .5rem;
          background: $grey;
          border: 1px solid $lightGrey;
          color: #FFF;
          border-radius: 5px;
          &:hover {
            background: $navy;
          }
          i {
            margin-bottom: 2px;
            font-size: 2rem;
            line-height: 2.2rem;
          }
          span {
            display: inline-block;
            // width: 20px; height: 20px;
            // border-radius: 50%;
            // background: #FFF;
            // color: $grey;
            color: #FFF;
            line-height: 21px;
            font-weight: 700;
          }

          &.add {
            margin-bottom: 0;
            border: 2px solid $lightGrey;
            color: $grey;
            background: #EFEFEF;
            i {
              margin-bottom: 0;
            }
            &:hover {
              border-color: $grey;
              background: $grey;
              color: #FFF;
            }
          }
        }
      }
      &--on {
        .powerIndemnitors__btm {
          margin-top: 0;
        }
      }
    }

    &--cost {
      header {
        @include flex;
        h5 {
          position: relative;
          margin: 0;
        }
        button {
          display: flex;
          @include bp(Mama) {
            display: none;
          }
        }
      }
      .costBreakdown {
        margin-top: .5rem;
        margin-bottom: .5rem;
        &__row {
          @include flex;
          // flex-direction: column;
          margin-bottom: .5rem;

          // @include bp(Mama) {
          //   flex-direction: row;
          // } 
          &__left {
            @include flex;
            justify-content: flex-start;
          }
          &__right {
            position: relative;
            @include flex;
            justify-content: flex-end;
            flex-direction: column-reverse;
            margin-left: .5rem;

            button {
              margin-bottom: 0.5rem;
            }

            @include bp(Mama) {
              flex-direction: row;

              button {
                margin-bottom: 0;
              }  
            }        

            i {
              color: $grey;
              margin-right: .25rem;
              font-size: 1.25rem;
            }
          }
        }
        &__breakdown {
          &__row {
            border: 1px solid $lightGrey;
            background: #FFF;
            border-top: 0;
            label {
              padding: .5rem;
            }
            input {
              padding: 0 .5rem 0 0;
              border: 0;
              background: #FAFAFA;
              outline: none;
              height: 35px;
              line-height: 35px;
              border-left: 1px solid $lightGrey;
              text-align: right;
              font-size: 1rem;
              @include bp(Mama) {
                padding-bottom: 0;
              }
            }
            strong {
              @include unicorn;

              @include bp(Mama) {
                padding-bottom: 0;
                padding: 0 1rem 0.5rem;
              }
          
            }
            &:first-child {
              border-top: 1px solid $lightGrey;
            }

            .bondPremium {
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;
              flex-direction: column;

              @include bp(Kid) {
                flex-direction: row;
                align-items: center;
              }

              &__quick {
                margin-right: .25rem;
                border: 1px solid $grey;
                background: $grey;
                border-radius: 3px;
                button {
                  width: 35px; height: 24px;
                  padding: 0;
                  border: 0;
                  border-right: 1px solid $lightGrey;
                  background: transparent;
                  font-weight: 700;
                  color: #FFF;
                  &:last-child {
                    border-right: 0;
                  }
                  &:hover {
                    background: $navy;
                  }
                }
              }
            }
          }
          &__actions {
            @include flex;
            padding: .5rem 0 1.5rem;
            button {
              width: 49%;
            }
          }
        }
      }
    }

    &--paymentPlan {
      position: relative;
      @include innerPod;
      .powerPaymentPlan {
        &--noResults {
          p {
            margin: .25rem 0 0;
            font-size: .85rem;
          }
          button {
            position: absolute;
            top: 50%; right: 10px;
            transform: translateY(-50%);
          }
        }
      }
    }

    &--actions {
      @include flex;
      justify-content: flex-end;
      gap: .5rem;
      margin-top: 2rem;
      display: none;

      @include bp(Mama) {
        display: flex;
      }
    }
  
    .costBreakdown__breakdown__row__right {
      display: flex;

      input {
        flex: 1;
      }

      @include bp(Kid) {
        display: block;
      }  
    }
  }

  &__add {
    margin-top: 2rem;
    button {
      width: 100%;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: 1rem 0;
      font-size: 3rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      &:hover {
        color: $navy;
      }
    }
  }

  &__adder {
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    color: $grey;
    cursor: pointer;
    border-radius: 10px;
    form, div.register__form {
      max-width: 100%;
      padding-bottom: 0;
      h5 {
        @include flex;
        margin: 0 0 1rem;
        font-size: .85rem;
        color: $grey;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .form-row:last-child {
        .inputRow__block {
          margin-bottom: 0;
        }
      }
      .register__form__actions {
        @include flex;
        button {
          width: 49.5%;
        }
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.transferRow {
  @include flex;
  & > * {
    &:nth-child(1) {
      flex: 0 0 calc(100% - 150px - 1rem);
    }
    &:nth-child(2) {
      flex: 0 0 150px;
    }
  }
}

.transferDetails {
  padding: .5rem;
  background: #FFF;
  border: 1px solid $lightGrey;
  margin-bottom: 1rem;
  border-radius: 5px;
  & > div {
    h3 {
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      font-size: .825rem;
    }
  }
}

.powerCourtDates {
  margin-top: .5rem;

  // padding-right: 2.25rem;
  p {
    margin: .25rem 0 0;
    font-size: .85rem;
  }

  .inputRow__block {
    margin: 0 0 .5rem;
  }

  &__actions {
    @include flex;

    &>button {
      width: 49.5%;
    }
  }
}

.mappingTool {
  &__clarify {
    margin-bottom: 1rem;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    background: #EFEFEF;
    padding: 1rem;

    &__rows {
      border-radius: 4px;
      border-bottom: 1px solid $lightGrey;
      background: #FFF;
    }

    &__row {
      @include flex;
      border-bottom: 1px solid $lightGrey;
      padding: .25rem;
      &:last-child {
        border-bottom: 0;
      }
      & > div {
        &:first-child {
          width: 30%;
        }
        &:last-child {
          width: 70%;
        }
      }
      .inputRow__block {
        margin: 0;
      }
    }
  }
  &__fields {
    &__header, &__row {
      @include flex;
      border-bottom: 1px solid $lightGrey;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
      padding: 0 .5rem .5rem;
      & > div {
        .inputRow__block {
          margin: 0;
        }
        &:nth-child(1) {
          width: 20%;
          margin-right: .5rem;
        }
        &:nth-child(2) {
          width: 50%;
          margin-right: .5rem;
        }
        &:nth-child(3) {
          width: 30%;
        }
      }

      &:last-child {
        margin-bottom: 0;
        border: 0;
      }
    } 

    &__header {
      margin: 0;
      padding: 0;
      background: #EFEFEF;
      & > div {
        padding: .5rem;
        text-align: center;
        font-weight: 700;
      }
    }

    &__midheader {
      margin: 0 0 .5rem;
      padding: 0;
      background: #444;

      &>h5 {
        padding: .5rem;
        color: #FFF
      }
    }
  }

  &--importer {
    .mappingTool__fields {
      padding: 0 !important;
    }
  }
}

.powerCourtInfo__selector {
  margin-bottom: .5rem;
  & > p {
    margin-bottom: .5rem !important;
  }
  &__inputs {
    @include flex;

    &__content {
      width: calc(100% - 78px);
      .inputRow__block {
        margin: 0 !important;
      }
    }

    &__actions {
      width: 70px;
      display: flex;
      justify-content: flex-end;
      gap: .25rem;
    }
  }

  &--adding {
    .powerCourtInfo__toggle {
      margin-top: .5rem;
    }
    .powerCourtInfo__selector__inputs {
      align-items: flex-start;
      &__content {
        width: calc(100% - 158px);
      }
      &__actions {
        padding-top: 5px;
        width: 150px;
      }
    }
  }
}

.powerCourtAppearance {
  @include flex;
  &__left {
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  &__right {
    padding-right: 5rem;
    .bigTag {
      padding: .25rem .5rem;
      font-size: 1.25rem;
      font-weight: 700;
      color: #FFF;
      background: $blue;
      text-transform: uppercase;
      border-radius: 4px;
    }
  }
  .appearanceButton {
    margin-top: .5rem;
  }
}

.powerForfeitures {
  &__form {
    &__primaryInfo {
      margin-top: .5rem;
      @include flex;
      gap: .5rem;
      & > * {
        flex: 1;
      }
    }
    &__secondaryInfo {
      margin-bottom: .5rem;
    }
    &__toggle {
      margin-bottom: .5rem;
    }
    &__uploader {
      margin: 1rem 0 0;
      height: 100%;
      overflow: hidden;
      &>div {
        padding: 2rem 0;
      }
    }
  }

  &__forfeiture {
    @include flex;
    &__left {
      h4 {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
    &__right {
      // padding-right: 5rem;
      .bigTag {
        padding: .25rem .5rem;
        font-size: 1.25rem;
        font-weight: 700;
        color: #FFF;
        background: $danger;
        text-transform: uppercase;
        border-radius: 4px;
      }
    }
  }
}

.formRowCharges {
  @include flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: .5rem;
  @include bp(Papa) {
    flex-direction: row;
  } 
  & > div {
    &:first-child {
      width: 100%;
      @include bp(Papa) {
        width: 20%;
      } 
    }
    &:last-child {
    width: 100%;
    @include bp(Papa) {
      width: 79%;
    } 
    }
  }
  .inputRow__block {
    margin-bottom: 0;
    p.small {
      text-align: left;
    }
  }
}

.formRowCourtInfo {
  margin-top: .5rem;
  @include flex;
  gap: .5rem;
  & > div {
    flex: 1;
  }

  .inputRow__block {
    margin-bottom: 0;
  }
}
 