.layout--paymentInfo {
  background: #EFEFEF;
  .defendant__header__container {
    background: #FFF;
    border-bottom: 2px solid $lightGrey;
    .defendant__header {
      max-width: 932px;
    }
  }
}

.paymentsManageLayout {
  position: relative;
}

.diffButton {
  position: absolute;
  top: 28px; right: 6px;
  height: 24px;
  &__container {
    position: relative;
  }
}

.template-payments {
  #root div.main > div {
    .layout {
      &__info {
        padding: 1.5rem 1rem;
      }
    }
  }
  div.main {
    & > div {
      background: #EFEFEF;
      .layout__info {
        max-width: 100%;
        padding-right: 30%;
      }
      .layout__info, .mainSearch {
        background: #FFF;
      }
    }
  }
}

.layout--payment {
  .register__form.payment__form {
    margin: 0 auto;
    max-width: 764px;
    padding: 0 1rem 1.5rem;
    .register__form__block {
      padding: 0;
    }
  }
  .register__form__actionsBar__actions {
    padding: 0 0 2rem;
  }
  .register__form__error {
    padding: 0;
    button {
      cursor: pointer;
    }
  }
}

header.defendant__header {
  margin: 0 auto;
  max-width: 764px;
  padding: 1.5rem 1rem;
  h3 {
    margin: 0 0 .5rem;
    font-size: 1.5rem;
    color: $navy;
  }
  p {
    margin: 0;
    color: $grey;
  }
}

.defendant__action {
  margin-bottom: 2rem;
  border-bottom: 2px solid $lightGrey;

  &__inner {
    margin: 0 auto;
    max-width: 764px;
    padding: 0 1rem 1rem;

    button {
      width: 100%;
    }
  }
}

.payments_confirmation {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 764px;

  & > p.big {
    margin-top: 0;
  }

  .twoCards_checkbox {
    margin-bottom: 1rem;
  }

  h5 {
    margin: 3rem 0 1rem;
    font-size: .85rem;
    color: $grey;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.payments_surety {
  margin: 1.5rem auto 0;
  padding: 0 1rem;
  max-width: 764px;

  h5 {
    margin: 0;
    font-size: .85rem;
    color: $grey;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &__totals {
    &__container {
      margin-bottom: 3rem;
    }
    & > h5 {
      margin-bottom: .5rem;
    }
    p.totalsInfo {
      margin: 0;
      font-size: .85rem;
    }
  }

  &__breakdown {
    & > h5 {
      @include flex;
      margin-bottom: .5rem;

      button {
        text-transform: uppercase;
      }
    }
  }

  .power {
    margin-bottom: .5rem;
    padding: 1rem;
    border: 2px solid $lightGrey;
    background: #FFF;
    border-radius: 5px;
    background: #FAFAFA;

    header {
      @include flex;
      .header__left {
        h3 {
          @include flex;
          justify-content: flex-start;
          gap: .5rem;
          margin: 0 0 .25rem;
          color: $teal;
          i {
            margin-right: .25rem;
          }
        }
        p {
          margin: 0 0 .25rem;
          font-size: .75rem;
          color: $grey;
          &:last-child {
            margin-bottom: 0;
          } 
        }
      }

      .header__tiles {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          padding: 0;
          width: 30px; height: 30px;
          background: transparent;
          border: 0;
          cursor: pointer;
          i {
            font-size: 1.25rem;
            color: $grey;
            &:hover {
              color: $navy;
            }
          }
        }
      }
    }

    &__details {
      margin-top: 1rem;
      &__block {
        @include innerPod;
        ol {
          margin: .25rem 0 0;
          padding: 0 0 0 1rem;
          li {
            font-size: .85rem;
          }
        }
        p {
          margin: .25rem 0 0;
          font-size: .85rem
        }
      }
    }
    
    .costBreakdown__details {
      @include innerPod;
      padding: 0;
      &__row {
        @include flex;
        padding: .5rem;
        border-bottom: 1px solid $lightGrey;
        &:last-child {
          border-bottom: 0;
        }

        &--total {
          background: $blue;
          color: #FFF;
          font-weight: 700;
        }
      }
    }
  }
}

.paymentsMade {
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $lightGrey;
  &__inner {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 764px;
  }
  h5 {
    margin: 0 0 .5rem;
    font-size: .85rem;
    color: $grey;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.creditCards {
  display: flex;
  justify-content: flex-start;

  .tile {
    max-width: 180px;
  }

  .tile--invoice {
    max-width: 220px;
    .invoiceContent {
      position: relative;
      label {
        display: block;
      }
      i {
        position: absolute;
        top: 50%; left: .5rem;
        transform: translateY(-50%);
        color: $teal;
        font-size: 2rem;
        margin-top: -2px;
      }
    }
    .invoicePayments {
      .tags {
        .tag:after {
          display: none;
        }
      }
      div {
        @include flex;
        padding: 4px 0;
        border-bottom: 1px solid $grey;
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
        label {
          margin: 0;
        }
        strong {
          font-size: .75rem;
        }
      }
    }
  }

  .tile--add {
    @include unicorn;
    padding: .5rem 0;
    width: 60px;  
    // height: 60px;
    color: $grey;
    cursor: pointer;
    min-width: 120px;
    &:hover {
      background: #EFEFEF;
      color: $navy;
    }
    i {
      margin-top: 3px;
      font-size: 2rem;
    }  
  }
}

.paymentsDashboard {
  width: 100%;
  // padding: 1rem 0;
  border-top: 1px solid $lightGrey;

  &__tiles {
    @include flex;
    gap: .25rem;
    & > div {
      flex: 1;
    }
  }

  .cancelledSwitch {
    @include flex;
    justify-content: flex-start;
    label {
      display: inline-block;
      margin-left: .5rem;
      font-size: .75rem;
      color: $grey;
    }
  }

  &__additional {
    margin-top: 1rem;
    padding-top: .75rem;
    border-top: 1px solid $lightGrey;
    &__top {
      @include flex;
      &__left {
        h4 {
          margin: 0 0 .25rem;
          color: $navy;
        }
        p {
          font-size: .825rem;
        }
      }
      &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .inputRow__block {
          margin: 0 .5rem 0 0;
        }
        button.butt {
          margin-right: .5rem;
        }
        button:not(.butt) {
          border: 0;
          background: transparent;
          padding: 0;
          font-size: 1.25rem;
          margin-right: .25rem;
          cursor: pointer;
          color: $grey;
          &:hover {
            color: $navy;
          }
        }
      }
    }
    &__btm {
      margin-top: 1rem;
      border-top: 2px solid $lightGrey;
      .paymentsPlanDetail__pod__right {
        .inputRow__block {
          margin: 0 .5rem 0 0;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__numbers {
      margin-top: 1rem;
      @include flex;
      & > div {
        flex: 1;
      }
    }
  }

  &__addPayment {
    margin-top: 1rem;
    padding: 0 1rem;
    button {
      width: 100%;
      background: #FAFAFA;
      border: 2px solid $lightGrey;
      padding: .25rem 0;
      font-size: 2rem;
      color: $grey;
      cursor: pointer;
      border-radius: 10px;
      outline: none;
      &:hover {
        color: $navy;
        background: #EFEFEF;
        border-color: $grey;
      }
    }
  }

  &__numbers {
    @include flex;
    flex-wrap: wrap;
    gap: .25rem;
    justify-content: flex-start;
    margin-top: 1rem;
    padding: 0 1rem;

    @include bp(Grand) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    & > div {
      flex: 1;
      margin: 0;
    }

    .tile--unaccounted {
      cursor: pointer;
    }
  }

  &__unAccounted {
    @include innerPod;
    border: 2px solid $lightGrey;
    margin: 1rem auto 0;
    max-width: 640px;

    h3 {
      margin: .25rem 0 .5rem;
      color: $navy;
    }
    p {
      margin: 0;
      font-size: .825rem;
    }

    &__actions, &__saveActions {
      margin-top: .5rem;
      @include flex;
      gap: .5rem;
      & > button {
        flex: 1;
      }
    }

    &__saveActions {
      padding: 0 1rem;
    }
  }

  &__explainer {
    padding: 1rem;
    background: #FFF;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    font-size: .825rem;
    line-height: 1.25rem;
  }

  &--list {
    padding-top: 0;
  }

  &__header {
    @include flex;
    padding: 1rem;
    margin-bottom: 1rem;
    background: #FFF;
    border-bottom: 1px solid $lightGrey;
    & > div {
      width: 50%;
    }

    &__left {
      margin-right: 1rem;
    }

    &__right {
      @include flex;
      & > div {
        &:first-child {
          width: 56px;
        }
        &:last-child {
          width: calc(100% - 56px);
        }
      }
    }
  }

  section {
    // margin-bottom: 2rem;
    // padding: 0 1rem;a

    h5.split {
      @include flex;
    }

    & > .planPod__container {
      margin-bottom: .5rem;
    }

    .paymentPlanCell {
      &:before, &:after {
        display: none;
      }
    }
  
    .noResults {
      background: #FFF;
      // padding: 1rem 0 0;
      border-radius: 10px;
    }

    .viewMoreButton {
      border: 0;
      background: transparent;
      padding: 0;
      font-family: 'Rubik', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      color: $grey;
      cursor: pointer;

      i {
        margin-left: .5rem;
      }

      &:hover {
        color: $navy;
      }

      &__container {
        text-align: right;
      }
    }
  }

  &--planDetail {
    section .planPod__container {
      margin-bottom: 0;
      cursor: default;
    }
    section > h5 {
      @include flex;
      > div {
        @include flex;
        justify-content: flex-end;
        gap: .5rem;
        letter-spacing: 0;
        a, button {
          text-transform: none;
        }
      }
    }
  }

  &__tiles {
    @include flex;
    gap: .25rem;
    justify-content: flex-start;

    @media (min-width: 1300px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &__tile {
      flex: 1;
      padding: 1rem;
      background: #FFF;
      border-radius: 10px;
      transition: $base-transition;
      border: 1px solid $lightGrey;
      cursor: pointer;
      text-align: left;
      font-size: 1rem;
      font-family: 'Rubik', sans-serif;
      margin: 0;
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 0 20px rgba(43, 56, 102, .25),  0 0 20px rgba(43, 56, 102, 0);
      }

      svg, i {
        font-size: 2.5rem;

        &.fa-circle-exclamation { color: $danger; }
        &.fa-circle-check { color: $completed; }
      }

      h4 {
        margin: 2rem 0 1rem;
        font-size: .825rem;
        color: $grey;
      }

      h2 {
        margin: 0;
        font-size: 2.5rem;
        color: $navy;
      }
    }
  }

  .paymentAlert {
    position: relative;
    display: block;
    width: 100%;
    // margin-top: 1rem;
    padding: 1rem;
    border: 0;
    border-radius: 4px;
    background: $grey;
    text-align: left;
    @include bp(Mama) {
      padding: 1rem 10rem 1rem 4.75rem;
    }

    strong {
      display: block;
      font-size: 1.25rem;
      color: #FFF;
    }

    p {
      margin: 0;
      padding: 0 !important;
      color: #FFF;
    }

    i {
      display: none;
      position: absolute;
      top: 50%; left: 1rem;
      margin-top: 2px;
      transform: translateY(-50%);
      color: #FFF;
      font-size: 3rem;
      @include bp(Mama) {
        display: block;
      }
    }

    button, a.butt {
      width: 100%;
      margin-top: 1rem;
      background: #FFF;
      color: $grey;
      border-color: #FFF;
      &:hover {
        background: #EFEFEF;
        border-color: #EFEFEF;
      }
      @include bp(Mama) {
        width: auto;
        margin-top: 0;
        position: absolute;
        top: 50%; right: 1rem;
        transform: translateY(-50%);
      }
    }

    a.butt {
      height: auto;
      padding: 8px 10px;
      font-size: .85rem;
      text-decoration: none;
    }

    &--teal {
      background: $teal;
    }

    &--navy {
      background: $navy;
    }

    &--blue {
      background: $blue;
    }

    &--red {
      background: $danger;

      button, a {
        background: #FFF;
        color: $danger;
        border-color: #FFF;
        &:hover {
          background: #EFEFEF;
          border-color: #EFEFEF;
        }
      }
    }

    &--tile {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 1rem 1rem 1rem 4.75rem;
      .blockAction__content {
        i {
          top: 1rem;
          transform: none;
        }
      }
      button {
        position: relative;
        top: auto; right: auto;
        margin-top: 1rem;
      }
    }
  }

  .graph__container {
    background: #FFF;
    padding: 1rem 0 0;
    border-radius: 10px;
  }

  
}

.planPod {
  position: relative;
  z-index: 1;
  @include pod;
  cursor: pointer;
  margin-bottom: 0;
  &__actions {
    position: relative;
    z-index: 0;
    @include flex;
    background: #FAFAFA;
    border: 2px solid $lightGrey;
    border-top: 0;
    padding: .5rem;
    margin-top: -48px;
    transition: $base-transition;
    & > div {
      width: 50%;
      button {
        margin-right: .25rem;
        height: 30px;
      }
      &:last-child {
        text-align: right;
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__container--open {
    .planPod__actions {
      margin-top: 0;
    }
  }
  &__left {
    h5 {
      margin: 0;
      font-weight: 300;
    }
    p {
      font-size: .75rem;
    }
  }
  &__right {
    p {
      font-size: .825rem;
      margin-right: .5rem;
    }
    .tags {
      margin: 0 .5rem 0 0;
      @include flex;
    }
    .tag {
      font-size: .825rem;
      &:after {
        display: none;
      }
    }
    i {
      font-size: 1.75rem;
      color: $grey;
    }
  }
  &--actions {
    border: 0;
    background: transparent;
    padding: 0;
    i {
      padding: .5rem;
      color: $grey;
      cursor: pointer;
      &:hover {
        color: $navy;
      }
    }
  }
  &:hover {
    .fa-chevron-right { color: $grey; }
  }
}

.paymentsPlanDetail {
  padding: 0 1rem;
  p.errorMessage {
    margin: .5rem 0 0;
    padding: .5rem;
    font-size: .7rem;
    background: $danger;
    color: #FFF;
  }
  &__timeFlag {
    padding: .5rem;
    background: $danger;
    border-left: 2px solid $lightGrey;
    border-right: 2px solid $lightGrey;
    p {
      margin: 0;
      font-size: .825rem;
      color: #FFF;
    }
  }
  &__pod {
    @include pod;
    flex-direction: column;
    border-top: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    &__top {
      position: relative;
      @include flex;
      width: 100%;
      padding: .5rem;
    }
    &__btm {
      width: 100%;
      padding: 0 .5rem .5rem;
      p.errorMessage {
        width: 100%;
        margin: 0;
      }
    }
    &__left {
      @include flex;
      justify-content: flex-start;
      i {
        font-size: 2rem;
        margin-right: .5rem;
      }
      i.fa-check-circle {
        color: $teal;
      }
      i.fa-exclamation-circle, .fa-piggy-bank, .fa-cog {
        color: $warning;
      }
      i.fa-times-circle, i.fa-circle-question {
        color: $danger;
      }
      i.fa-circle {
        color: $grey;
      }
      h4 {
        color: $navy;
        font-size: 0.85rem;

        @include bp(Mama) {
          font-size: 1rem;
        }      
      }
      p {
        font-size: 0.75rem;

        @include bp(Mama) {
          font-size: .825rem;
        }
      }
    }
    &__right {
      .amount {
        margin-right: .5rem;
        font-size: .825rem;
        font-weight: 700;
        color: $grey;
      }
      .tags {
        @include flex;
        margin: 0;
        .tag {
          font-size: 0.75rem;

          @include bp(Mama) {
            font-size: .825rem;
          }

          &:after {
            display: none;
          }
        }
      }
    }
    .spreadEquation {
      i.fa-plus {
        margin-right: .5rem;
      }
      input {
        max-width: 70px;
        text-align: center;
      }
      span {
        font-size: .825rem;
      }
    }
    .editButton, .deleteButton {
      display: none;
      position: absolute;
      top: 5px; 
      right: .5rem;
      padding: 0;
      background: transparent;
      border: 0;
      font-size: 1.1rem;
      cursor: pointer;

      @include bp(Kid) {
        top: 50%; 
        transform: translateY(-50%);
      }
    }
    .editButton {
      right: 2rem;
    }
    .deleteButton {
      color: $danger;
    }
    &--initial {
      background: #fffbd6;
    }
  }
  &__received, &__edit {
    @include flex;
    row-gap: 0.5rem;
    width: 100%;
    padding: .5rem;
    margin-top: .5rem;
    border-top: 2px solid $lightGrey;
    background: #FAFAFA;

    &__left {
      h4 {
        margin: 0 0 .25rem;
        font-size: 0.75rem;
        color: $navy;
      }
      p {
        margin: 0;
        font-size: 0.65rem;
      }
    }

    &__right {

      @include bp(Kid) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
  
      .inputRow__block {
        margin-bottom: 0.5rem;

        @include bp(Kid) {
          margin: 0 .5rem 0 0;
        }
      }
      button {
        height: 30px;
      }
    }
  }
  &__edit {
    margin: 0;
    // border-top: none;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    @include bp(Mama) {
      border-left: 1px solid $lightGrey;
    }

    @media (min-width: 1300px) {
      flex-direction: row;
      align-items: center;
    }

    &__left {
      padding-right: calc(40px + 1rem);
    }

    &__right {
      & > button {
        border: 0;
        background: transparent;
        padding: 0;
        font-size: 1.25rem;
        margin-right: .25rem;
        cursor: pointer;

        position: absolute;
        top: 0.5rem;
        right: calc(20px + 0.75rem);

        &:last-child {
          margin-right: 0;
          right: 0.5rem;
        }
        i {
          &.fa-circle-check { color: $teal; }
          &.fa-circle-xmark { color: $danger; }
        }

        @include bp(Kid) {
          position: static;
        }    
      }
    }
  }
}