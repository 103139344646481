.noResults {
  h3 {
    padding: 0;

    strong {
      color: #4259ED;
    }
  }
  .searchResults {
    &--noResults {
      border-top: 1px solid #DFE6F4;
      border-bottom: 1px solid #DFE6F4;
      padding: .75rem 0;
    }
  }
  .register__form__actionsBar__actions {
    padding: 0 0 37px;
  }
  .register__form__actionsBar {
    &__actions {
      padding: 0 0 10px;

      &:last-child {
        padding-bottom: 37px;
        button {
          width: 100%;
          -webkit-appearance:none;
        }
      }
    }
  }
}

.agreeToTerms {
  padding: 1rem;
  border: 4px solid $lightGrey;
  border-radius: 10px;
  background: #EFEFEF;
  max-width: 400px;
  margin: 105px 1rem 0;
  @include bp(Mama) {
    margin: 0 1rem;
  }

  &__image {
    padding: 1rem 0;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    p {
      font-size: .825rem;
      text-align: center;
    }
    a {
      color: $navy;
      &:hover {
        color: $teal;
      }
    }
  }
  
  &__actions {
    button {
      width: 100%;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  &__mask {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(255,255,255,.85);
    z-index: 100;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include bp(Mama) {
      position: fixed;
      height: calc(var(--vh, 100vh));
      @include unicorn;
    }
  }

  &__info {
    .register__form {
      padding: 0;
    }
  }

  &__notifications {
    @include flex;
    margin-bottom: 1rem;
    & > div {
      width: 50%;
      & > label {
        display: block;
        font-size: .75rem;
        margin-bottom: .5rem;
      }
    }
  }

  .verifyHeader {
    text-align: center;
    color: $navy;
    margin: .5rem 0 2rem;
  }

  .closeButton {
    border: 0;
    font-size: .825rem;
    font-weight: 300;
    padding: 1rem 0 0;
  }
}

.editProfileButton {
  display: none;
  margin-top: 1rem;
  i {
    margin-right: .5rem;
  }
  @include bp(Mama) {
    display: inline-block;
  }
}

.detailsFound {
  h4 {
    margin: 0 0 1rem;
    padding: 0;
  }
  header {
    border: 1px solid #DFE6F4;
    & > div {
      display: flex;
      justify-content: space-between;
      padding: 4px;
      font-size: .85rem;
      border-bottom: 1px solid #DFE6F4;
      span {
        text-transform: uppercase;

        strong {
          display: block;
          @include bp(Mama) {
            display: inline;
          }
        }
      }
    }
    h2 {
      margin: 0;
      text-align: center;
      padding: 1rem 0;
      background: #FAFAFA;
    }
  }
  &__warrants {
    // display: none;
    // padding-top: 1rem;
    border: 1px solid #DFE6F4;
    border-top: 0;
    &__charges {
      margin: 1rem 0 0;
      padding: .5rem;
      border-top: 1px solid #DFE6F4;
      border-bottom: 1px solid #DFE6F4;
      background: #EFEFEF;
    }
    &__warrant {
      p {
        // display: none;
        margin: 0;
        padding: 1rem 1rem 0;
        strong {
          display: inline-block;
          font-size: .875rem;
          color: #6F7F9F;
          margin-bottom: 4px;
        }
      }
      &:last-child {
        .detailsFound__warrants__charges {
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .register__form__actionsBar {
    margin-top: 2rem;
    &__actions {
      padding: 0 0 37px;
    }
  }
}

.defendantStatus {
  padding-top: 45px;
  padding: 0 20px;
  @include bp(Mama) {
    padding: 0 40px;
  }
  &__task {
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    font-size: .85rem;
    color: #6F7F9F;
    min-height: 50px;

    @include bp(Mama) {
      font-size: 1.125rem;
      min-height: 60px;
      padding-right: 116px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0; left: 14.5px;
      margin-left: -1px;
      width: 2px; height: 100%;
      background: #DFE6F4;
      @include bp(Mama) {
        left: 22.5px;
      }
    }

    p.small {
      margin: .5rem 0 0;
      font-size: .75rem;
    }

    span {
      position: relative;
      z-index: 1;
      margin-right: 1rem;
      display: inline-block;
      width: 30px; height: 30px;
      border: 2px solid #DFE6F4;
      background: #A2AFCD;
      color: #FFF;
      border-radius: 50%;
      line-height: 0;

      i {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        font-style: normal;
      }
      @include bp(Mama) {
        width: 45px; height: 45px;
      }
    }

    & > div {
      width: calc(100% - 30px - 1rem)
    }

    &--waiting {
      // .waitingTask {
      //   background: $blue;
      //   color: #FFF;
      // }
      span {
        background: $warning;
      }
    }

    &--inset {
      padding-left: 2.5rem;

      &:after {
        content: '';
        position: absolute;
        top: 505; left: 14.5px;
        margin-top: -1px;
        width: 30px; height: 2px;
        background: #DFE6F4;
        @include bp(Mama) {
          left: 22.5px;
        }
      }

      span {
        width: 20px; height: 20px;
        margin-right: .5rem;
        i { font-size: .75rem; }
        @include bp(Mama) {
          width: 26px; height: 26px;
        }
      }

      // button.butt {
      //   width: 100%;
      //   @include bp(Mama) {
      //     position: absolute;
      //     top: 50%; right: 0;
      //     transform: translateY(-50%);
      //     max-width: 100px;
      //     margin-top: 0;
      //   }
      // }
    }

    &--finished {
      span {
        border-color: #24C0AC;
        background: #55FAC3;
      }
    }

    &--inprogress {
      span {
        border-color: #DBAB00;
        background: $warning;
      }
    }

    &--defendantFound {
      padding-top: 2px;
    }

    &--bankInfo {
      .register__form__block {
        padding: 1.5rem 0 0;
      }
      .register__form__actionsBar__actions {
        padding: 0 0 37px;
      }
      .register__form__error {
        margin: 1rem 0 0;
        padding: 0;
      }
    }

    &:first-child {
      &:before {
        top: 50%;
        height: 50%;
      }
    }

    &:last-child {
      &:before {
        height: 50%;
      }
    }
  }

  .butt {
    margin-top: 5px;
    width: 100%;
    @include bp(Mama) {
      position: absolute;
      top: 50%; right: 0;
      transform: translateY(-50%);
      max-width: 100px;
      margin-top: 0;
    }
  }

}

.layout--defendantStatus {
  max-width: 700px;

  .noticeWrapper {
    margin: 3rem auto 0;
    padding: 2rem;
    border: 4px solid $navy;
    border-radius: 10px;
    max-width: 350px;
    text-align: center;

    &__container {
      padding: 0 1rem;
      @include bp(Mama) {
        padding: 0 2rem;
      }
    }

    p {
      margin: 0 0 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      &.big {
        margin-bottom: .5rem;
      }
      &:not(.big) {
        color: $grey;
      }
    }

    &--welcome {
      p a {
        color: $navy
      }
      button {
        padding: 0 3rem;
      }
    }
  }

  .defendantArea--checkinActive {
    .register__form__info,
    .defendantHighLevelInfo,
    .defendantCharges,
    .defendantCourthouse,
    .defendantTasks,
    .defendantAdditionalCourtDates,
    .timeline {
      display: none;
    }
  }

}

.defendantAdditionalCourtDates {
  padding: 0 1rem;
  @include bp(Mama) {
    padding: 0;
  }
}

.bondAgencies {
  position: relative;
  margin-bottom: 50px;
  padding: 0 40px;

  &::before {
    content: '';
    position: absolute;
    top: 50px; left: 0;
    width: 100%; height: calc(100% - 100px);
    background: #F3F5FF;
  }

  &__agency {
    position: relative;
    z-index: 1;
    margin: 0 auto 30px;
    border: 2px solid #dfe6f4;
    background: #FFF;
    border-radius: 5px;
    max-width: 600px;

    &:last-child {
      margin-bottom: 0;
    }

    &__container {
      padding: 1rem;
    }

    h3 {
      margin: 0;
      font-size: 1.125rem;
      color: #2D3766;
    }

    p {
      margin: 2px 0 0;
      font-size: .875rem;
      color: #6F7F9F;

      &.bondAgencies__agency__serving {
        span {
          &::after {
            content: ', ';
          }

          &:last-child {
            &::before {
              content: 'and ';
            }
            &::after {
              content: '';
            }
          }
        }
      }
    }

    &__stars {
      margin-top: 6px;
      i.fa-star {
        margin-right: 2px;
        color: #FFD450;
      }
    }
    
    .fa-chevron-right {
      position: absolute;
      top: 50%; right: 20px;
      width: 30px; height: 30px;
      transform: translate(0, -50%);
      line-height: 30px;
      text-align: center;
      font-size: 2rem;
    }

    &__container {
      padding-right: 110px;
    }

    .butt {
      position: absolute;
      top: 50%; right: 20px;
      transform: translate(0, -50%);
    }

    &__employees {
      padding-bottom: .75rem;
      border-top: 2px solid #dfe6f4;
      p {
        padding: 1rem 1rem .25rem;
      }
      &__employee {
        position: relative;
        cursor: pointer;
        padding: .5rem 1rem;
        &:last-child {
          margin-bottom: 0;
        }
        strong {
          margin: 0;
          font-size: 1.125rem;
          color: #2D3766;
        }
    
        .fa-chevron-right {
          right: 0;
          font-size: 1rem;
        }

        &:hover {
          background: #EFEFEF;
        }
      }
    }
  }
}

.layout--register {
  .register__form {
    &--agents {
      .register__form__info {
        max-width: 100%;
        p {
          text-align: center;
        }
      }
    }
  }
  .bondAgencies {
    &:before {
      display: none;
    }
    h3 {
      padding: 0;
    }
  }
}

.recoveryConfirmed {
  margin-top: 3rem;
  text-align: center;
  a {
    color: $navy;
    &:hover {
      color: $teal;
    }
  }
}

.pending_case {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
  }
  &__name {
    font-size: 1.25rem;
    font-weight: 700;
  }
  &__createdOn {
    font-size: .75rem;
  }
  &__content {
    
  }
  &__actions {

  }
}

.pod {
  position: relative;
  z-index: 1;
  margin: 0 auto 30px;
  padding: 20px;
  border: 2px solid #dfe6f4;
  background: #FFF;
  border-radius: 5px;
  max-width: 600px;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    margin: 0;
    font-size: .875rem;
    font-weight: 300;
    text-transform: uppercase;
  }

  h3 {
    margin: 0;
    font-size: 1.125rem;
    color: #2D3766;
  }

  p {
    margin: 2px 0 0;
    font-size: .875rem;
    color: #6F7F9F;
    a {
      color: #6F7F9F;
      text-decoration: none;
    }
  }

  &__section {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #dfe6f4;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.bondAgencySelected {
  position: relative;
  margin-bottom: 50px;
  padding: 0 40px;

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    button {
      width: 49%;
    }
  }

  i.fa-star {
    margin-right: 2px;
    color: #FFD450;
  }  
}

.paymentPlanText {
  // border-bottom: 1px solid $lightGrey;
  p {
    margin: 0 auto;
    padding-bottom: 1rem;
    max-width: 764px;
  }
}

.layout--paymentPlan {
  background: #EFEFEF;
}

.payments {

  max-width: 764px;
  margin: 0 auto;
  padding: 0 1rem;
  @include bp(Mama) {
    padding: 0;
  }

  &__container {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 632px;
  }

  // h3 {
  //   text-align: center;
  //   max-width: 500px;
  //   margin: 0 auto;
  //   color: #4259ED;
  //   font-size: 1.25rem;
  //   padding: 1rem 0;
  // }

  .defendant__header {
    @include bp(Mama) {
      padding: 2rem 0;
    }
    h3 { 
      margin: 0 0 .5rem;
      padding: 0;
    }
  }

  p.notice {
    margin: 0 0 1.5rem;
    font-size: 1.125rem;
    color: #6f7f9f;
    text-align: center;
  }

  p.noPayments {
    text-align: center;
  }

  p.small {
    padding: 0 1rem;
    @include bp(Mama) {
      padding: 0;
    }
  }

  &__invoice {
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid $lightGrey;
    background: #FFF;
    &__line {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #DFE6F4;
      padding: 1rem;
      &:first-child {
        border-top: 0;
      }
      &--total {
        border-top: 3px solid #2D3766;
      }
      &--totalBondAmount {
        border-bottom: 3px solid #2D3766;
      }
      &--rebate {
        strong { color: $danger; }
      }
    }
  }
  &__paymentsMade {
    position: relative;
    padding: 0 1rem;
    @include bp(Mama) {
      margin-bottom: 1rem;
      padding: 0;
      border-bottom: 3px solid #2D3766;
    }

    &__items {
      &__item {
        border: 1px solid #DFE6F4;
        margin-bottom: 1rem;

        h4 {
          margin: 0 0 1rem;
          padding: 1rem;
        }
      }
    }

    &__details {
      padding: 0 1rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__breakdown {
      width: 100%;
      p {
        margin: 0 0 1em;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__total {
      border-top: 1px solid #DFE6F4;
      background: #DFE6F4;
      color: #222;
      padding: .5rem 0;
      text-align: center;
      span {
        display: block;
        font-size: .75rem;
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-size: 1.25rem;
      }
    }

    &__actions {
      @include flex;
      margin-bottom: 1rem;

      & > button {
        flex: 1;
        &:first-child {
          flex: 0 0 20%;
        }
        &:last-child {
          flex: 0 0 78%;
        }
      }

      &__trigger {
        width: 100%;
        background: #EFEFEF;
        border: 0;
        font-size: 1.25rem;
        padding: 2rem 0;
        border: 2px dashed #999;
        margin-bottom: 1rem;
        cursor: pointer;

        &--additional {
          padding: 1.5rem 0;
          font-size: 1rem;
          background: #FAFAFA;
          border: 2px dashed #DFDFDF;
        }

        &:last-child {
          margin-bottom: 0;
        }

        span {
          margin-top: .25rem;
          display: block;
          font-size: .75rem
        }
      }

      .quoteText {
        font-weight: 700;
        text-align: center;
      }

      .paymentAcknowledgement {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background: rgba(255,255,255,.95);
        padding: 2rem;
        border: 2px solid $teal;

        @include unicorn;
        flex-direction: column;

        &__content {
          margin: 0 0 1rem;
          font-weight: 700;
        }

        .cb {
          margin-bottom: 1rem;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;

          .cb__checkbox {
            margin: .3rem .5rem 0 0;
            cursor: pointer;
          }

          .cb__text {
            margin: 0;
            font-size: .75rem;
          }
        }
      }
    }
  }
}

.twoCards {
  &_checkbox {
    .cb {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
  
      &__text {
        margin: 0 0 0 1rem;
        font-size: .85rem;
      }
    }
  }
  &__privacy {
    font-size: .7rem;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.payment__form {
  .amount-row {
    position: relative;

    button {
      position: absolute;
      top: 25px; right: 4px;
      height: 30px;
    }

  }
  .totals-row {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #DFE6F4;
      padding: .5rem 0;
      border-radius: 5px;

      span, strong {
        display: block;
      }

      span {
        margin-bottom: .5rem;
        font-size: .75rem;
        text-transform: uppercase;
        color: darken( #DFE6F4, 30% );
      }

      strong {
        font-size: 2rem;
        color: darken( #DFE6F4, 50% )
      }
    }
  }
  .twoCards_checkbox {
    padding: 0 0 1.5rem;
  }
}

.payments__paymentsMade {
  .twoCards_checkbox {
    padding: 0 0 1.5rem;
  }
}

.cardExamples {
  padding: 1rem 0 2rem;
  display: block;
  font-size: .875rem;
  color: #6F7F9F;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.forgotPassword {
  &__instructions {
    text-align: center;
    color: #6F7F9F;
    font-size: .85rem;
    padding: 0 2rem;
    @include bp(Mama) {
      padding: 0;
      font-size: 1rem;
    }
    button {
      padding: 0;
      border: 0;
      background: transparent;
      font-size: .85rem;
      font-weight: 600;
      color: #4259ED;
      outline: none;
      @include bp(Mama) {
        font-size: 1rem;
      }
    }
  }
  .register__form__actionsBar__actions {
    button {
      width: 100%;
    }
  }
}

.register__form__info--faq {
  margin-top: 5rem;
  p {
    text-align: center;
  }
}

.filesArea {
  &__types p {
    text-align: center;
    strong {
      display: block;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    button {
      width: 49%;
    }

    &--complete {
      flex-direction: column;
      button {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
  &__completions {
    button {
      height: 20px;
      cursor: pointer;
      font-size: .7rem;
    }
  }
  &__reset {
    margin-top: 1rem;
    padding: 1rem;
    border: 2px solid #6F7F9F;
    background: #DFE6F4;

    @include bp(Toddler) {
      display: flex;
      justify-content: space-between;
    }

    & > p {
      margin: 0;
      font-size: 0.75rem;
      padding-right: 0.25rem;
      margin-bottom: 0.5rem;

      @include bp(Toddler) {
        font-size: 1em;
        margin-bottom: 0;
      }
    }
    & > div {
      display: flex;
    }

    button {
      margin-left: .5rem;
      &:first-child {
        margin: 0;
      }
    }
  }
}

.layout--secureUpload {
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 100px;

  .register__form__error {
    margin-top: 2rem;
  }
}

.secureUpload {
  max-width: 600px;
  margin: 0 auto;

  &__note {
    margin: 0 1rem;
    margin: .5rem 0 1rem;
    padding: .5rem;
    border: 2px solid $danger;
    border-radius: .25rem;
    background: #ffcece;
    p {
      margin: 0;
      font-size: 1.1rem;
      text-align: center;
      strong {
        color: $navy;
        font-weight: 900;
        &.red {
          color: $danger;
        }
      }
    }
  }

  &__lightNote {
    display: flex;
    gap: 5px;
    margin: 0 1rem;
    margin: .5rem 0 1rem;
    padding: .5rem;
    border-radius: .25rem;
    background: $orange;

    svg {
      color: white;
    }

    p {
      margin: 0;
      font-size: 14px;
      text-align: left;
      color: white;

      strong {
        color: black;
        font-weight: 900;
        &.red {
          color: $danger;
        }
      }
    }
  }

  &__text {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: .5rem;
    border: 1px solid $teal;
    background: #f8ffff;
    border-radius: 5px;
    color: $teal;
    
    @include bp(Mama) {
      display: flex;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
    button {
      width: 120px;
      margin-left: 1rem;

      i {
        font-size: 1.5rem;
      }
    }
  }

  &__trouble {
    @include flex;
    margin: 1rem 0;
    padding: .5rem;
    border: 2px solid $grey;
    background: $grey;
    border-radius: 10px;

    h4, p {
      margin: 0;
      color: #FFF;
      font-size: .75rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    button {
      width: 120px;
      margin-left: 1rem;

      i {
        font-size: 1.5rem;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    button {
      padding: 0 1rem;
      min-width: 90px;
    }
  }
  
  &__block {
    padding: .75rem 1rem;
    border-top: 2px solid #DFE6F4;
    border-bottom: 2px solid #DFE6F4;

    @include bp(Mama) {
      padding: .75rem 0;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: $navy;
    }

    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }

  &__meta {
    // text-align: center;
    margin-bottom: .75rem;

    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    & > div {
      @include unicorn;
      width: 49%;
      border: 2px solid #DFE6F4;
      background: transparent;
      font-size: 2rem;
      color: #DFE6F4;
      padding: 2rem 0;
      outline: none;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;

      &:hover {
        border-color: $navy;
        color: $navy;
      }

      .secureUpload__buttons__image {
        padding: 1rem;
        img {
          max-width: 100%;
          height: auto;
          max-height: 200px;
        }
      }
    }

    div.button--isUpdated {
      padding: .75rem 0;
      border-color: $navy;
      color: $navy;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        border-color: $navy;
        color: $navy;
      }

      strong {
        font-size: .85rem;
        text-transform: uppercase;
      }

      button {
        // margin-top: 2rem;
        padding: 0;
        border: 0;
        background: transparent;
        color: $navy;
        cursor: pointer;
        font-weight: 700;

        &:hover {
          color: $teal;
        }
      }
    }
  }

  &__block--full {
    .secureUpload__buttons {
      div {
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .mask__container {
    p.lower {
      margin: 1em 0 0;
    }
  }
}

.noteArea {
  margin-top: 2rem;
  padding: 1rem;
  border: 2px solid #DFE6F4;
  background: #FAFAFA;

  p {
    margin: 0;
  }

  &__title {
    padding-bottom: .4rem;
    border-bottom: 2px solid #DFE6F4;
  }

  &__content {
    padding-top: 1rem;
    font-size: .85rem;
  }
}

.files__questionairArea__display__row {
  margin-bottom: 1.5rem;
  .files__questionairArea__textareaContainer {
    position: relative;
  
    textarea {
      padding-right: 120px;
    }
  
    button {
      position: absolute;
      top: 50%; right: 10px;
      transform: translateY(-50%);
      // margin-top: -10px;
      height: 22px;
    }
  }
  p.small {
    margin: 0 0 1rem;
    font-size: .75rem;
    font-weight: 400;
    text-align: right;
  }
}

.butt__finish {
  padding: 0 2rem;
}


.floatingSubmitBar {
  position: fixed;
  bottom: 0; left: 0;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__actions {
    button {
      margin-right: .5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px; left: 0;
    height: 5px;
    width: 100%;
    background: $navy;
  }

  background: #E8EBFC;
  
}

.secureUploaderMessage {
  margin: 1rem auto 0;
  text-align: center;
  @include bp(Mama) {
    margin: 2rem auto 0;
    width: 70%;
  }

  p:not(.big) {
    font-size: .85rem;
  }
  button {
    padding: 0 2rem;
  }
}

form.timelineNoteForm {
  position: relative;
  display: flex;
  border: 1px solid #DFE6F4;
  border-radius: 4px;
  padding: .5rem;
  background: #FFF;

  input[type="text"] {
    padding: .5rem;
    width: calc(100% - 70px);
    border: 0;
    background: transparent;
    outline: none;
  }

  input[type="submit"] {
    position: absolute;
    top: 50%; right: 6px;
    transform: translateY(-50%);
    width: 60px;
  }
}

.secondIndemnitor_requestForm {
  max-width: 464px;
  margin: 0 auto;
  padding: 0 2rem;

  header {
    padding: 2rem 0;
    h2 {
      margin: 0 0 .25rem;
      color: $navy;
    }
    p {
      margin: 0;
    }
  }

  .register__form__block {
    padding: 0;
    .split-row {
      .formBlock:first-child {
        margin-left: 0;
      }
    }
  }

  .register__form__actionsBar__actions {
    padding: 0;  
  }

  &__sent {
    p.big {
      margin-top: 0;
    }
    .sendRequest {
      width: 100%;
    }
  }

}
